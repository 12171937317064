import { useEffect, useState } from "react";

interface UseClientWindowSizeReturn {
  windowWidth: number;
  windowHeight: number;
  valid: boolean;
}

const useClientWindowSize = (): UseClientWindowSizeReturn => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [valid, setValid] = useState(false);

  const resizeHandler = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
    setValid(true);
  };

  useEffect(() => {
    addEventListener("resize", resizeHandler);
    resizeHandler();

    return () => {
      removeEventListener("resize", resizeHandler);
    };
  }, []);

  return {
    windowWidth,
    windowHeight,
    valid,
  };
};
export default useClientWindowSize;
