import {
  ProductDetailResponse,
  ProductKind,
  ProductResponse,
} from "@/web-client/api";
import { useCallback } from "react";
import { AnalyticsItemParameter } from "@/types/AnalyticsItemParameter";

type AnalyticsCategoryParam = Pick<
  AnalyticsItemParameter,
  | "item_category"
  | "item_category2"
  | "item_category3"
  | "item_category4"
  | "item_category5"
  | "item_category6"
>;

type PropductWithCategory = ProductDetailResponse | ProductResponse;

type UseConvertProductToAnalyticsCategoryParamReturn = {
  convert: (product: PropductWithCategory) => AnalyticsCategoryParam;
};

const useConvertProductToAnalyticsCategoryParam =
  (): UseConvertProductToAnalyticsCategoryParamReturn => {
    const convert = useCallback(
      (product: PropductWithCategory): AnalyticsCategoryParam => {
        return product.categories.reduce(
          (acc, value, index) => {
            acc[`item_category${index + 2}`] = value.name;

            return acc;
          },
          {
            item_category:
              product.kind === ProductKind.INTERIOR
                ? "interior"
                : "building_material",
          },
        );
      },
      [],
    );

    return { convert };
  };
export default useConvertProductToAnalyticsCategoryParam;
