import {
  addSample,
  changeOrderCountOfSample,
  ProductVariationWithSample,
  removeSample,
  clearAllSamples,
  SampleWithQuantity,
  setQuestionnaire,
  clearQuestionnaire,
  MaxQuantityOfSameMakerItems,
  setOrder as setOrderOnStore,
  clearOrder as clearOrderOnStore,
} from "@/stores/cart";
import { RootState } from "@/stores/rootReducer";
import {
  MakerResponse,
  OrderWithGroupsResponse,
  OrderCreateParameterOrderItemsInner,
  OrderCreateParameterQuestionnaire,
  ProductResponse,
  SampleItemEntity,
  SampleRequestKind,
} from "@/web-client/api";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAnalytics from "./useAnalytics";
import useConvertSampleInCartToAnalyticsParam from "./useConvertSampleInCartToAnalyticsParam";

export type OrganizedSamples = {
  maker: MakerResponse;
  samples: SampleWithQuantity[];
};

export type OutOfStockSample = {
  sample: SampleWithQuantity;
  currentStockCount: number;
};

export type DuplicateCheckResult = { quantity: number; duplicate: boolean };

type UseCartReturn = {
  samplesInCart: SampleWithQuantity[];
  orderItems: OrderCreateParameterOrderItemsInner[];
  hasItems: boolean;
  questionnaire: OrderCreateParameterQuestionnaire;
  productVariationIds: number[];
  organizedSamples: {
    indirect: OrganizedSamples[];
    direct: OrganizedSamples[];
  };
  isCapacityOver: boolean;
  quantity: number;
  numberOfMakers: number;
  latestItem: SampleWithQuantity;
  order: OrderWithGroupsResponse;

  addSampleToCart: (sample: SampleWithQuantity) => void;
  removeItemInCart: (
    productVariationWithSample: ProductVariationWithSample,
  ) => void;
  removeAllItemsInCart: VoidFunction;
  changeOrderCountOfSampleInCart: (
    productVariationWithSample: ProductVariationWithSample,
    product: ProductResponse,
    quantity: number,
  ) => void;
  setQuestionnaireForOrder: (
    questionnaire: OrderCreateParameterQuestionnaire,
  ) => void;

  setOrder: (order: OrderWithGroupsResponse) => void;
  clearOrder: VoidFunction;

  reset: VoidFunction;
  hasSameItem: (
    productVariationWithSample: ProductVariationWithSample,
  ) => boolean;
  hasSameSampleItem: (
    productVariationWithSample: ProductVariationWithSample,
  ) => boolean;

  getOutOfStockSamples: (samples: SampleItemEntity[]) => OutOfStockSample[];

  checkDuplicateBeforeAddToCart: (
    productVariation: ProductVariationWithSample,
  ) => DuplicateCheckResult;
};

// メーカーごとにサンプルをまとめる関数
const organizeSamples = (samples: SampleWithQuantity[]) => {
  return samples.reduce<OrganizedSamples[]>((acc, sample) => {
    const maker = sample.product.maker;
    const makerSamples = acc.find((a) => a.maker.id === maker.id);

    if (makerSamples) {
      makerSamples.samples.push(sample);
    } else {
      acc.push({
        maker,
        samples: [sample],
      });
    }

    return acc;
  }, []);
};

const useCart = (): UseCartReturn => {
  const dispatch = useDispatch();
  const { event } = useAnalytics();
  const { convert } = useConvertSampleInCartToAnalyticsParam();
  const { samples, questionnaire, order } = useSelector(
    (state: RootState) => state.cart,
  );

  const addSampleToCart = useCallback(
    (sample: SampleWithQuantity) => {
      dispatch(addSample({ ...sample, updated_at: new Date().toISOString() }));
      event("add_to_cart", {
        maker_id: sample.product.maker_id,
        items: [convert(sample)],
      });
    },
    [dispatch, event, convert],
  );

  const changeOrderCountOfSampleInCart = useCallback(
    (productVariation, product, quantity) => {
      dispatch(
        changeOrderCountOfSample({
          productVariation,
          product,
          quantity,
          updated_at: new Date().toISOString(),
        }),
      );
    },
    [dispatch],
  );

  const removeItemInCart = useCallback(
    (productVariation) => {
      const target = samples.find(
        (sample) => sample.productVariation.id === productVariation.id,
      );

      if (target) {
        event("remove_from_cart", {
          maker_id: target.product.maker_id,
          items: [convert(target)],
        });
      }
      dispatch(removeSample(productVariation));
    },
    [dispatch, event, convert, samples],
  );

  const removeAllItemsInCart = useCallback(() => {
    dispatch(clearAllSamples());
  }, [dispatch]);

  const setQuestionnaireForOrder = useCallback(
    (questionnaire) => {
      dispatch(setQuestionnaire(questionnaire));
    },
    [dispatch],
  );

  const removeQuestionnaireForOrder = useCallback(() => {
    dispatch(clearQuestionnaire());
  }, [dispatch]);

  const setOrder = useCallback(
    (order) => {
      dispatch(setOrderOnStore(order));
    },
    [dispatch],
  );

  const clearOrder = useCallback(() => {
    dispatch(clearOrderOnStore());
  }, [dispatch]);

  const reset = useCallback(() => {
    removeAllItemsInCart();
    removeQuestionnaireForOrder();
  }, [removeAllItemsInCart, removeQuestionnaireForOrder]);

  const orderItems = useMemo<OrderCreateParameterOrderItemsInner[]>(() => {
    return samples.map<OrderCreateParameterOrderItemsInner>(
      ({ quantity, productVariation, additional_data, board_id }) => {
        return {
          sample_item_id: productVariation.sample_item?.id,
          product_variation_id: productVariation.id,
          quantity,
          additional_data,
          board_id,
        };
      },
    );
  }, [samples]);

  const hasItems = useMemo(() => samples.length > 0, [samples]);

  const productVariationIds = useMemo(
    () => samples.map((s) => s.productVariation.id),
    [samples],
  );

  // 同じアイテムがないかをチェックする
  const hasSameItem = useCallback(
    (productVariation: ProductVariationWithSample) => {
      return samples.some(
        (sample) => sample.productVariation.id === productVariation.id,
      );
    },
    [samples],
  );

  // 同じアイテムがないかをチェックする
  const hasSameSampleItem = useCallback(
    (productVariation: ProductVariationWithSample) => {
      // サンプルがひもづいていない場合は
      // プロダクトバリエーションのidで比較する
      if (!productVariation.sample_item) {
        return samples.some(
          (sample) => sample.productVariation.id === productVariation.id,
        );
      }

      return samples.some(
        (sample) =>
          productVariation.sample_item.id ===
          sample.productVariation.sample_item?.id,
      );
    },
    [samples],
  );

  // メーカー直送サンプル
  const directSamples = useMemo(
    () =>
      samples.filter(
        (sample) =>
          sample.productVariation.sample_request_kind ===
          SampleRequestKind.DIRECT,
      ),
    [samples],
  );

  // TSSサンプル
  const indirectSamples = useMemo(
    () =>
      samples.filter(
        (sample) =>
          sample.productVariation.sample_request_kind ===
            SampleRequestKind.INDIRECT || !!sample.productVariation.sample_item,
      ),
    [samples],
  );

  // TSSサンプルとメーカー直送サンプルを、メーカーごとにまとめたもの
  const organizedSamples = useMemo(() => {
    return {
      direct: organizeSamples(directSamples),
      indirect: organizeSamples(indirectSamples),
    };
  }, [directSamples, indirectSamples]);

  // １つのメーカーに対して、最大数を超えるサンプルが入っているかどうかを
  // TSS、メーカー直送のそれぞれでチェックする
  const isCapacityOver = useMemo(() => {
    const { direct, indirect } = organizedSamples;

    const checkCapacityOver = ({ samples }) =>
      samples.reduce((acc, cur) => acc + cur.quantity, 0) >
      MaxQuantityOfSameMakerItems;

    return direct.some(checkCapacityOver) || indirect.some(checkCapacityOver);
  }, [organizedSamples]);

  // 在庫切れのサンプルを引数に、カート内とてらしあわせOutOfStockSampleの配列を返す
  const getOutOfStockSamples = useCallback(
    (outOfStockSampleItems: SampleItemEntity[]) => {
      return outOfStockSampleItems.map((outOfStockSampleItem) => {
        const target = indirectSamples.find(
          (sample) =>
            sample.productVariation.sample_item.id === outOfStockSampleItem.id,
        );

        return {
          sample: target,
          currentStockCount: outOfStockSampleItem.stock_count,
        };
      });
    },
    [indirectSamples],
  );

  // カートに入っているサンプルの個数
  const quantity = useMemo(() => {
    return samples.reduce((acc, cur) => acc + cur.quantity, 0);
  }, [samples]);

  // 最も最近カートの中身でアップデート（追加・数量変更）されたアイテム
  const latestItem = useMemo(() => {
    const parsedObjects = samples
      .map((sample) => ({
        ...sample,
        compare_at: new Date(sample.updated_at), // 比較用Date
      }))
      .sort((a, b) => b.compare_at.getTime() - a.compare_at.getTime());

    return parsedObjects[0];
  }, [samples]);

  // カートに入っているサンプルのメーカーの数
  const numberOfMakers = useMemo(() => {
    const { direct, indirect } = organizedSamples;
    const directMakerIds = direct.map((d) => d.maker.id);
    const indirectMakerIds = indirect.map((d) => d.maker.id);

    // 重複を除いたメーカーIDの配列
    const uniqueIds = Array.from(
      new Set([...directMakerIds, ...indirectMakerIds]),
    );

    return uniqueIds.length;
  }, [organizedSamples]);

  // 指定のプロダクトがカートに入っているかどうかを確認し、重複の有無とカートに入れる場合の数量を返す
  const checkDuplicateBeforeAddToCart = useCallback(
    (productVariation) => {
      if (hasSameItem(productVariation)) {
        const same = samples.find(
          (sample) => sample.productVariation.id === productVariation.id,
        );
        const newQuantity = same.quantity + 1;

        return { duplicate: true, quantity: newQuantity };
      }

      return { duplicate: false, quantity: 1 };
    },
    [samples, hasSameItem],
  );

  return {
    samplesInCart: samples,
    orderItems,
    questionnaire,
    addSampleToCart,
    removeItemInCart,
    changeOrderCountOfSampleInCart,
    removeAllItemsInCart,
    setQuestionnaireForOrder,
    reset,
    hasSameItem,
    hasSameSampleItem,
    getOutOfStockSamples,
    checkDuplicateBeforeAddToCart,
    hasItems,
    productVariationIds,
    organizedSamples,
    isCapacityOver,
    quantity,
    numberOfMakers,
    latestItem,
    order,
    setOrder,
    clearOrder,
  };
};
export default useCart;
