import { useMemo } from "react";
import tailwindConfig from "../../tailwind.config.js";
import useClientWindowSize from "./useClientWindowSize";

interface UseDeviceReturn {
  valid: boolean;
  isSp: boolean;
  isPc: boolean;
}

const useDevice = (): UseDeviceReturn => {
  const { valid, windowWidth } = useClientWindowSize();

  const isSp = useMemo<boolean>(() => {
    return windowWidth < parseInt(tailwindConfig.theme.screens.laptop.min, 10);
  }, [windowWidth]);

  return {
    valid,
    isSp,
    isPc: !isSp,
  };
};

export default useDevice;
