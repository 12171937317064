import { FC, PropsWithChildren } from "react";

type Props = {
  borderStyle?: "solid" | "dotted";
  className?: string;
};

const BorderList: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  borderStyle = "dotted",
}) => {
  return (
    <ul
      className={[
        `[&>*+*]:border-t [&>*+*]:border-primary`,
        `${
          borderStyle === "dotted"
            ? "[&>*+*]:border-dotted"
            : "[&>*+*]:border-solid"
        }`,
        className,
      ].join(" ")}
    >
      {children}
    </ul>
  );
};

export default BorderList;
