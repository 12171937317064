import clsx from "clsx";
import { FC, ReactNode, useMemo, PropsWithChildren } from "react";
import { theme } from "tailwind.config";

type Props = {
  badgeContent: number;
  maxBadgeContent?: number;
  badgeContentColor?: string;
  badgeColor?: string;
  offsetX?: number;
  offsetY?: number;
};

const Badge: FC<PropsWithChildren<Props>> = ({
  badgeContent,
  badgeColor,
  children,
  badgeContentColor = theme.colors.black,
  offsetX = -8,
  offsetY = -8,
}): JSX.Element => {
  const displayBadgeContent = useMemo<ReactNode>(() => {
    if (badgeContent >= 100) {
      return `99+`;
    }

    return badgeContent;
  }, [badgeContent]);

  return (
    <span className="relative block">
      {badgeContent > 0 && (
        <span
          className={clsx(
            "absolute py-[3px] px-6 rounded-full leading-none text-xs font-bold pointer-events-none whitespace-nowrap",
            badgeColor || "bg-yellow-500",
          )}
          style={{
            color: badgeContentColor,
            right: `${offsetX}px`,
            top: `${offsetY}px`,
          }}
        >
          {displayBadgeContent}
        </span>
      )}
      {children}
    </span>
  );
};
export default Badge;
