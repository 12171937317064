import Link from "next/link";
import { FC } from "react";
import IconLaunch from "../assets/imgs/svg/icon_launch.svg";
import { theme } from "tailwind.config";
import { RECRUIT_URL, USER_CONTACT_URL } from "@/config/env";

const ContactURL = "https://share.hsforms.com/14YcrUmuMTxqBv1g075w70Qce6bs";

const TheFooter: FC = (): JSX.Element => {
  return (
    <footer className="bg-black laptop:pt-64 laptop:px-64 pt-40 px-24 pb-24 text-white tracking-wider space-y-24 @container">
      <div className="flex flex-col justify-between items-start gap-64 laptop:pb-64 pb-24 @[1024px]:flex-row @[1024px]:gap-24">
        <div className="flex flex-col gap-40 laptop:flex-row laptop:gap-[96px]">
          <div className="space-y-16">
            <Link
              href="/"
              prefetch={false}
              className="block SVGWrapper"
              style={{ width: 180 }}
            >
              <img src="/logo-white.svg" alt="TECTURE" />
            </Link>
            <p className="text-2xs text-secondary">
              TECTURE is Database for all architects.
            </p>
          </div>

          <div className="flex flex-col gap-80 laptop:flex-row laptop:gap-24">
            <NavigationGroup
              title="SEARCH"
              items={[
                {
                  label: "建築をさがす",
                  link: "/architectures",
                },
                {
                  label: "建材をさがす",
                  link: "/building_materials",
                },
                {
                  label: "家具をさがす",
                  link: "/interiors",
                },
              ]}
            />

            <NavigationGroup
              title="COMPANY"
              items={[
                {
                  label: "TECTUREとは？",
                  link: "/about",
                },
                {
                  label: "よくあるご質問",
                  link: "/faq",
                },
                {
                  label: "メーカーの方へ",
                  link: "/ads/maker",
                },
                {
                  label: "利用規約",
                  link: "/terms",
                },
                {
                  label: "プライバシーポリシー",
                  link: "/privacy",
                },
                {
                  label: "運営会社",
                  link: "https://about.tecture.jp/",
                  outer: true,
                },
                {
                  label: "採用情報",
                  link: RECRUIT_URL,
                  outer: true,
                },
                {
                  label: "お問い合わせ",
                  link: USER_CONTACT_URL,
                  outer: true,
                },
              ]}
            />

            <NavigationGroup
              title="MEDIA"
              items={[
                {
                  label: "TECTURE MAG",
                  link: "https://mag.tecture.jp/",
                  outer: true,
                },
              ]}
            />
          </div>
        </div>

        <div className="grow laptop:grow-0 bg-gray-700 p-20 space-y-32 rounded-sm w-full @[1024px]:max-w-[300px]">
          <div className="space-y-12">
            <p className="font-bold text-sm">建材・家具メーカーの方へ</p>
            <p className="text-xs leading-loose">
              TECTUREを活用してみませんか？
              登録ユーザーの74%が建築・インテリアの設計者なので、
              プロダクトの認知向上にきっと役立つはずです。
            </p>
          </div>

          <div className="flex flex-row gap-8">
            <Link
              href={"/ads/maker"}
              className="inline-block bg-yellow-500 rounded-full px-16 py-12 text-primary text-xs"
            >
              詳細を見る
            </Link>

            <Link
              href={ContactURL}
              className="inline-block bg-yellow-500 rounded-full px-16 py-12 text-primary text-xs"
              target="_blank"
            >
              お問い合わせ
            </Link>
          </div>
        </div>
      </div>
      <hr style={{ borderColor: theme.colors.gray[900] }} />
      <div className="flex flex-col laptop:flex-row justify-between items-center gap-24 laptop:gap-64">
        <ul className="flex gap-24 text-lg laptop:text-2xl">
          <li>
            <a
              className="no-underline"
              href="https://twitter.com/tecture1"
              target="_blank"
            >
              <i className="icon-Twitter" />
            </a>
          </li>
          <li>
            <a
              className="no-underline"
              href="https://www.instagram.com/tecture_official/"
              target="_blank"
            >
              <i className="icon-Instagram" />
            </a>
          </li>
          <li>
            <a
              className="no-underline"
              href="https://www.facebook.com/tecture1/"
              target="_blank"
            >
              <i className="icon-Facebook" />
            </a>
          </li>

          <li>
            <a
              className="no-underline"
              href="https://www.youtube.com/channel/UCIogLuLLvO7cTbqb34N1AVg/"
              target="_blank"
            >
              <i className="icon-Youtube" />
            </a>
          </li>
        </ul>

        <small className="text-2xs">
          &copy;TECTURE Inc. All rights reserved.
        </small>
      </div>
    </footer>
  );
};

export default TheFooter;

type NavigationItem = {
  link: string;
  label: string;
  outer?: boolean;
};

type NavigationGroupProps = {
  title: string;
  items: NavigationItem[];
};

const NavigationGroup: FC<NavigationGroupProps> = ({
  title,
  items,
}): JSX.Element => {
  return (
    <div className="space-y-32 min-w-[160px]">
      <p className="text-2xs font-bold">{title}</p>
      <ul className="space-y-12">
        {items.map(({ link, label, outer }) => (
          <li key={label}>
            <Link
              href={link}
              className="flex items-center gap-8 text-sm hover:underline"
              target={outer ? "_blank" : "_self"}
            >
              {outer ? (
                <>
                  <span>{label}</span>
                  <IconLaunch width={12} height={12} fill="#aaa" />
                </>
              ) : (
                <>{label}</>
              )}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
