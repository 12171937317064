import { useCallback } from "react";
import { AnalyticsItemParameter } from "@/types/AnalyticsItemParameter";
import useConvertProductToAnalyticsCategoryParam from "./useConvertProductToAnalyticsCategoryParam";
import { SampleWithQuantity } from "@/stores/cart";

type UseConvertSampleInCartToAnalyticsParamReturn = {
  convert: (sample: SampleWithQuantity) => AnalyticsItemParameter;
};

const useConvertSampleInCartToAnalyticsParam =
  (): UseConvertSampleInCartToAnalyticsParamReturn => {
    const { convert: convertToCategoryParam } =
      useConvertProductToAnalyticsCategoryParam();

    const convert = useCallback(
      (sample: SampleWithQuantity) => {
        return {
          item_id: `PRODV_${sample.productVariation.id}`,
          item_name: sample.productVariation.full_name,
          item_variant: sample.productVariation.item_number || "",
          item_brand: sample.product.maker.name,
          quanity: sample.quantity,
          ...convertToCategoryParam(sample.product),
        };
      },
      [convertToCategoryParam],
    );

    return {
      convert,
    };
  };
export default useConvertSampleInCartToAnalyticsParam;
